import React from "react";
import "./more-games.scss";

const MoreGames = ({ moreGames, onGameClick }) => {
  return (
    <div className="more-games">
      <div className="title">More Games</div>
      {moreGames.map(({ name, image, routename }) => (
        <div
          className="game-list"
          key={name}
          onClick={() => onGameClick(routename)}
        >
          <img className="game-image" src={image} alt={name} />
          <div className="game-title">{name}</div>
        </div>
      ))}
    </div>
  );
};

export default MoreGames;
