import React from "react";
import { IconStore } from "./icons-store";
import "./icons.scss";

const Icon = ({ className, name, onClick }) => {
  const IconComponent = IconStore[name];
  return (
    <div className={`${className} icon-div`} onClick={() => onClick()}>
      <IconComponent />
    </div>
  );
};

export default Icon;
