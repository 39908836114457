import React from "react";
import Icon from "@icons";
import { HeaderLogo } from "@images";
import { AddToHomeScreen } from "@components";
import "./header.scss";

const Header = ({ menuClick, title = "" }) => {
  return (
    <div className="header">
      <div className="items-left">
        <Icon name="himenu" className="menu-icon" onClick={menuClick} />
        <div className="title">{title}</div>
      </div>
      <div className="items-right">
        <AddToHomeScreen />
        <img className="logo" src={HeaderLogo} alt="Logo" />
      </div>
    </div>
  );
};

export default Header;
