import { HiMenu } from "react-icons/hi";
import { ImCross } from "react-icons/im";
import { MdAddToHomeScreen } from "react-icons/md";
import { MdAddToQueue } from "react-icons/md";
import { IoClose } from "react-icons/io5";

export const IconStore = {
  himenu: HiMenu,
  imcross: ImCross,
  addtohomescreen: MdAddToHomeScreen,
  addtoqueue: MdAddToQueue,
  ioclose: IoClose,
};
