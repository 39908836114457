import React from "react";
import { Overlay } from "@components";
import Icon from "@icons";
import "./side-bar.scss";

const SideBar = ({ title, visible = false, onClose, children }) => {
  return (
    <>
      <div
        style={{
          ...(!visible ? { width: 0 } : {}),
        }}
        className="side-bar"
      >
        <div className="side-bar-header">
          <div className="title">{title}</div>
          <Icon className="side-bar-close" name="imcross" onClick={onClose} />
        </div>
        {children}
      </div>

      <Overlay visible={visible} />
    </>
  );
};

export default SideBar;
