import { filterGamesStatus } from "@utils";
import GAME_LABELS from "@game-labels";
import GameTypes from "./constants";

const INITIAL_STATE = {
  currentGame: {},
  remainingGames: [],
};

const gameReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GameTypes.UPDATE_CURRENT_GAME: {
      const { selectedGame, remainingGames } = filterGamesStatus(
        GAME_LABELS,
        action.payload
      );
      return {
        ...state,
        currentGame: { ...selectedGame },
        remainingGames: [...remainingGames],
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default gameReducer;
