import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { refreshCacheAndReload } from "../../redux/game-reducer/actions";
import packageJson from "../../../package.json";
global.appVersion = packageJson.version;

function CacheBuster({ children, refreshCacheAndReload }) {
  const [cacheStatus, setCacheStatus] = useState({
    loading: true,
    isLatestVersion: false,
  });

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("pragma", "no-cache");
    myHeaders.append("cache-control", "no-cache");
    const myInit = {
      method: "GET",
      headers: myHeaders,
    };
    fetch("/meta.json", myInit)
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;

        const shouldForceRefresh = isThereNewVersion(
          latestVersion,
          currentVersion
        );
        if (shouldForceRefresh) {
          console.log(
            `We have a new version - ${latestVersion}. Should force refresh`
          );
          setCacheStatus({
            ...cacheStatus,
            loading: false,
            isLatestVersion: false,
          });
        } else {
          console.log(
            `You already have the latest version - ${latestVersion}. No cache refresh needed.`
          );
          setCacheStatus({
            ...cacheStatus,
            loading: false,
            isLatestVersion: true,
          });
        }
      });
  }, []);

  const isThereNewVersion = (metaVersion, currentVersion) => {
    const metaVersions = metaVersion.split(/\./g);
    const currentVersions = currentVersion.split(/\./g);
    while (metaVersions.length || currentVersions.length) {
      const a = Number(metaVersions.shift());
      const b = Number(currentVersions.shift());
      if (a === b) continue;
      return a > b || isNaN(b);
    }
    return false;
  };

  const refreshCacheAndReloadAsync = () => {
    if (caches) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
          console.log(name, " deleted");
        });
        console.log("Hard reload");
        window.location.reload();
      });
    }
  };

  return children({
    ...cacheStatus,
    cacheCallback: refreshCacheAndReloadAsync,
  });
}

const mapDispatchToProps = (dispatch) => ({
  refreshCacheAndReload: () => dispatch(refreshCacheAndReload()),
});

export default connect(null, mapDispatchToProps)(CacheBuster);
