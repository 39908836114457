export const filterGamesStatus = (gameList, pathname) => {
  let selectedGame, remainingGames;

  remainingGames = gameList.filter((item) => {
    if (item.routename === pathname) {
      selectedGame = { ...item };
    }
    return item.routename !== pathname;
  });

  return { selectedGame, remainingGames };
};
