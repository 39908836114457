import React from "react";
import { AppleStore, GoogleStore } from "@images";
import "./mobile-store.scss";

const MobileStore = () => {
  return (
    <div className="store-logos">
      <div className="title">Download Daily Word Puzzle</div>
      <div className="links">
        <img className="" src={GoogleStore} alt="google-store" />
        <img src={AppleStore} alt="apple-store" />
      </div>
    </div>
  );
};

export default MobileStore;
