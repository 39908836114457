import GameTypes from "./constants";

export const updateCurrentGame = (pathname) => {
  return {
    type: GameTypes.UPDATE_CURRENT_GAME,
    payload: pathname,
  };
};

export const refreshCacheAndReload = () => {
  console.log("Action called");
  return {
    type: GameTypes.REFRESH_CACHE_AND_RELOAD,
  };
};
