import React from "react";
import { FaBook, WhiteBP } from "@images";
import "./links.scss";

const Links = () => {
  return (
    <div className="links">
      <div className="title">Links</div>
      <a href="https://www.bombayplay.com" target="_self">
        <img src={WhiteBP} alt="bombay-play" />
        <span>Visit Bombay Play</span>
      </a>
      <a href="https://www.bombayplay.com" target="_self">
        <img src={FaBook} alt="privacy-policy" />
        <span>Privacy Policy</span>
      </a>
    </div>
  );
};

export default Links;
