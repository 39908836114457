import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  Divider,
  Header,
  Links,
  MobileStore,
  MoreGames,
  SideBar,
} from "@components";
import "./container.scss";

const Container = ({ currentGame, remainingGames }) => {
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const navigate = useNavigate();
  const title = currentGame.name && currentGame.name.toUpperCase();

  return (
    <div className="container">
      <Header menuClick={() => setSidebarVisible(true)} title={title} />
      <SideBar
        visible={sidebarVisible}
        onClose={() => setSidebarVisible(false)}
        title={title}
      >
        <MobileStore />
        <Divider />
        <MoreGames
          moreGames={remainingGames}
          onGameClick={(routename) => navigate(routename)}
        />
        <Divider />
        <Links />
      </SideBar>

      <Outlet />
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentGame: state.gameReducer.currentGame,
  remainingGames: state.gameReducer.remainingGames,
});

export default connect(mapStateToProps)(Container);
