import React, { useEffect, useRef } from "react";
import { isBrowser } from "react-device-detect";
import { Button, Overlay } from "@components";
import { AddLogo, DailyWord } from "@images";
import Icon from "@icons";
import "./add-to-home-screen.scss";

const AddToHomeScreen = () => {
  console.log("a2h: Add to homescreen component");

  const ref = useRef();
  const deferredPrompt = useRef();
  const promptPopupRef = useRef();
  const singleShowRef = useRef();

  useEffect(() => {
    ref.current.style.display = "none";
    promptPopupRef.current.style.display = "none";
    singleShowRef.current = true;

    window.addEventListener(
      "beforeinstallprompt",
      handleBeforeInstallationPrompt
    );

    window.addEventListener("appinstalled", handleAfterAppInstalled);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallationPrompt
      );
      window.removeEventListener("appinstalled", handleAfterAppInstalled);
    };
  }, []);

  const handleBeforeInstallationPrompt = (e) => {
    e.preventDefault();
    deferredPrompt.current = e;
    ref.current.style.display = "block";
    console.log("a2h: single show ", singleShowRef.current);
    if (singleShowRef.current) {
      promptPopupRef.current.style.display = "block";
      singleShowRef.current = false;
    }
  };

  const handleAfterAppInstalled = () => {
    console.log("app installed");
    ref.current.style.display = "none";
    promptPopupRef.current.style.display = "none";
  };

  const handleInstallationPrompt = (e) => {
    ref.current.style.display = "none";
    deferredPrompt.current.prompt();
    deferredPrompt.current.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === "accepted") {
        console.log("a2h: User accepted the A2HS prompt");
      } else {
        console.log("a2h: User dismissed the A2HS prompt");
      }
      deferredPrompt.current = null;
    });
  };

  const handlePromptAction = (action) => {
    promptPopupRef.current.style.display = "none";
    if (action === "add") {
      handleInstallationPrompt();
    }
  };

  return (
    <div>
      <div ref={promptPopupRef}>
        <div className="prompt-popup">
          <div className="title">Enjoy Puzzles? Create a Shortcut!</div>
          <div className="app-details">
            <img className="daily-word-logo" src={DailyWord} alt="game-logo" />
            <img className="add-logo" src={AddLogo} alt="add-ogo" />
          </div>
          <div className="prompt-buttons">
            <Button
              className="add-button"
              onClick={(e) => handlePromptAction("add")}
            >
              ADD
            </Button>
            <Button
              className="skip-button"
              onClick={(e) => handlePromptAction("cancel")}
            >
              SKIP
            </Button>
          </div>
        </div>
        <Overlay />
      </div>
      <div ref={ref}>
        <Icon
          iconRef={ref}
          name={isBrowser ? "addtoqueue" : "addtohomescreen"}
          className="install-icon"
          onClick={handleInstallationPrompt}
        />
      </div>
    </div>
  );
};

export default AddToHomeScreen;
