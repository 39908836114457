import { DailyWord, DiceMerge } from "@images";

const GAME_LABELS = [
  {
    routename: "/daily-word",
    image: DailyWord,
    name: "The Daily Word",
  },
  {
    routename: "/dice-merge",
    image: DiceMerge,
    name: "Dice Merge Puzzle",
  },
];

export default GAME_LABELS;
