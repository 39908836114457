import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { AddUnit } from "@components";
import "./game-frame.scss";

const GameFrame = ({ version }) => {
  const { gamename } = useParams();

  useEffect(() => {
    // (window.adsbygoogle = window.adsbygoogle || []).push({});
    // (window.adsbygoogle = window.adsbygoogle || []).push({});
    // (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <div className="game-frame">
      {/* <AddUnit slotId="9432741469" /> */}
      <div className="game-box">
        <iframe
          src={`${
            process.env.PUBLIC_URL +
            "/games/" +
            gamename +
            `/index.html?${version}`
          }`}
          title={gamename}
        />
        {/* <div className="bottom-ad">
          <AddUnit
            slotId="8848718269"
            height="100px"
            width="100%"
            alwaysVisible
          />
        </div> */}
      </div>
      {/* <AddUnit slotId="9738345255" /> */}
    </div>
  );
};

export default GameFrame;
