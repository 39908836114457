import React, { useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { updateCurrentGame } from "./redux/game-reducer/actions";
import { Container, GameFrame } from "@pages";
import { initSingular } from "./singular/singular.utils";
import packageInfo from "../package.json";
import "./app.scss";

function App({ updateCurrentGame }) {
  const { pathname } = useLocation();
  const navigateTo = useNavigate();

  useEffect(() => {
    console.log("ENV: ", process.env.NODE_ENV);
    console.log("Initializing Singular ...");
    initSingular();
  }, []);

  useEffect(() => {
    if (pathname !== "/") {
      updateCurrentGame(pathname);
    } else {
      navigateTo("daily-word");
    }
  }, [pathname, updateCurrentGame, navigateTo]);

  return (
    <div className="App">
      <Routes>
        <Route path=":gamename" element={<Container />}>
          <Route index element={<GameFrame version={packageInfo.version} />} />
        </Route>
      </Routes>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  updateCurrentGame: (pathname) => dispatch(updateCurrentGame(pathname)),
});

export default connect(null, mapDispatchToProps)(App);
