import React from "react";
import ReactDOM from "react-dom/client";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import App from "./app";
import { CacheBuster, Loader } from "@components";
import "./index.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <CacheBuster>
        {({ loading, isLatestVersion, cacheCallback }) => {
          if (loading) return <Loader />;
          if (!loading && !isLatestVersion) {
            cacheCallback();
          }
          return <App />;
        }}
      </CacheBuster>
    </BrowserRouter>
  </Provider>
);

serviceWorkerRegistration.register();
