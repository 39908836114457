import { singularSdk, SingularConfig } from "singular-sdk";

const API_KEY = "bombayplayinc_62964278";
const SECRET_KEY = "5d6f99d838f2271a6bc73fa0bab84857";
const PRODUCT_ID = "com.thedailywordgame.site";

const config = new SingularConfig(API_KEY, SECRET_KEY, PRODUCT_ID);

export const initSingular = () => {
  config.withInitFinishedCallback((initParams) => {
    console.log("Singular InitParams: ", initParams);
    singularSdk.event("Singular Initilization Done");
  });
  singularSdk.init(config);
};
