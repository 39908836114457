import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import GameTypes from "./constants";

function* refreshCacheAndReload() {
  try {
    console.log("Clearing cache");
    if (caches) {
      const names = yield caches.keys();
      yield Promise.all(names.map((name) => caches.delete(name)));
    }
    yield console.log("Hard reloading...");
    yield window.location.reload(true);
  } catch (err) {
    console.log(err.message);
  }
}

function* onRefreshCacheAndReload() {
  yield takeEvery(GameTypes.REFRESH_CACHE_AND_RELOAD, refreshCacheAndReload);
}

export default function* gameSagas() {
  yield all([call(onRefreshCacheAndReload)]);
}
